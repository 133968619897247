import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { collection, query, where, onSnapshot, addDoc, doc, setDoc, Timestamp, updateDoc, arrayUnion } from "firebase/firestore";
import { auth, firestore, firebase } from '../../../Services/firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
    wigTypes,
    hairType,
    brand,
    condition,
    hairOriginLookup,
    laceTypeLookup,
    trimming,
    densityLookup,
    reasonForSelling,
    wigListingStatus,
    wigApprovalStatus,
    deliveryStatus,
    wigStatus
} from "./WigsLookupFile";
import moment from "moment";
import _ from "lodash"
import { Button, ButtonGroup } from "@mui/material";
import { toast } from "react-toastify";

function createData(wigImage, wigId, wigName, wigReviewStatus, listingStatus, deliveryStatus, price, wigProcessingStatus, fullData) {
    return {
        wigImage,
        wigId,
        wigName,
        listingStatus,
        wigReviewStatus,
        deliveryStatus,
        price,
        fullData,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],

    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const wigInfo = row.fullData

    function removeWig() {

        const currentDoc = doc(firestore, "sellers", props.userId, 'wigs', row.wigId)
        updateDoc(currentDoc, {
            displayToUser: false,
            modifiedAt: moment().utc().unix()
        })

        toast.error('Wig Been Removed', { theme: "colored", autoClose: false })

    }

    function requestHelp() {
        const currentCollection = collection(firestore, "helpList")
        addDoc(currentCollection, {
            userId: props.userId,
            wigInfo: wigInfo,
            modifiedAt: moment().utc().unix(),
            dateAdded: moment().utc().unix(),
            dateAddedReadable: moment().format("dddd, MMMM Do YYYY, h:mm:ss a")
        })
        toast.info('Our representive will reach out to you within 24hrs', { autoClose: false, theme: "colored", })
    }

    useEffect(() => {
        console.log('checking wiginfo:', wigInfo)
        console.log('checking row data ROW:', row)
    })

    return (
        <React.Fragment>
            <TableRow >
                <TableCell align="center" >
                    {row.wigImage && <img
                        width={'50px'}
                        // height={'60%'}
                        //   key={index}
                        src={row.wigImage}
                        alt="Uploaded Image"



                    />}
                </TableCell>

                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.wigId}
                </TableCell>


                {/* hairType[_.findIndex(hairType, ['value', wigInfo.hairType])].label */}
                <TableCell >{row.wigName}</TableCell>
                {/* <TableCell >{wigApprovalStatus[_.findIndex(wigApprovalStatus, ['value', wigInfo.wigReviewStatus])].label}</TableCell> */}
                <TableCell >{wigStatus[_.findIndex(wigStatus, ['value', wigInfo.wigProcessingStatus])].label} </TableCell>
                {/* <TableCell >{deliveryStatus[_.findIndex(deliveryStatus, ['value', wigInfo.deliveryStatus])].label}</TableCell> */}
                <TableCell >{wigListingStatus[_.findIndex(wigListingStatus, ['value', wigInfo.listingStatus])].label}</TableCell>
                <TableCell align='center' >
                    <ButtonGroup>
                        <Button onClick={() => { removeWig() }} variant={'contained'} color='error'> Remove </Button>
                        <Button onClick={() => { requestHelp() }} variant={'contained'} >Request Help</Button>
                        {/* <Button>Three</Button>  */}
                    </ButtonGroup>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Wig Information
                            </Typography>
                            <Table size="large" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >You Bought The Hair</TableCell>
                                        <TableCell>Hair Piece Type</TableCell>
                                        <TableCell>Condition Of Hair</TableCell>
                                        <TableCell>Hair Fiber Origin</TableCell>
                                        <TableCell>Lace Trimming</TableCell>
                                        <TableCell>Reason For Selling</TableCell>
                                        <TableCell>Hair Brand</TableCell>
                                        <TableCell>Hair Length</TableCell>
                                        <TableCell>Hair Piece Dimensions</TableCell>
                                        <TableCell>Hair Density</TableCell>
                                        <TableCell>How Much You Bought Your Hair For</TableCell>
                                        <TableCell>Mininum hair sale price</TableCell>
                                        <TableCell>Lace Type</TableCell>
                                        {/* <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        <TableCell component="th" scope="row">
                                            {moment.unix(row.fullData.ageOfHair).fromNow()}
                                        </TableCell>
                                        <TableCell >{hairType[_.findIndex(hairType, ['value', wigInfo.hairType])].label}</TableCell>
                                        <TableCell >{condition[_.findIndex(condition, ['value', wigInfo.hairCondition])].label}</TableCell>
                                        <TableCell >{hairOriginLookup[_.findIndex(hairOriginLookup, ['value', wigInfo.hairOrigin])].label}</TableCell>
                                        <TableCell >{trimming[_.findIndex(trimming, ['value', wigInfo.wigTrimming])].label}</TableCell>
                                        <TableCell >{reasonForSelling[_.findIndex(reasonForSelling, ['value', wigInfo.reasonForSelling])].label}</TableCell>
                                        <TableCell >{brand[_.findIndex(brand, ['value', wigInfo.hairBrand])].label}</TableCell>
                                        <TableCell >{wigInfo.hairLength}</TableCell>
                                        <TableCell >{wigInfo.wigDimensions}</TableCell>
                                        <TableCell >{densityLookup[_.findIndex(densityLookup, ['value', wigInfo.hairDensity])].label}</TableCell>
                                        <TableCell  >{'$' + wigInfo.purchasePrice}</TableCell>
                                        <TableCell >{'$' + wigInfo.salePrice}</TableCell>
                                        <TableCell >{laceTypeLookup[_.findIndex(laceTypeLookup, ['value', wigInfo.wigCapType])].label}</TableCell>
                                        {/* <TableCell>{historyRow.customerId}</TableCell>
                                        <TableCell align="right">{historyRow.amount}</TableCell>
                                        <TableCell align="right">
                                            {Math.round(historyRow.amount * row.price * 100) / 100}
                                        </TableCell> */}
                                    </TableRow>
                                </TableBody>
                                {/* <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody> */}
                            </Table>
                        </Box>
                        <Box sx={{ margin: 3 }}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                                Hair Piece Images
                            </Typography> */}
                            <Table size="large" aria-label="purchases">
                                <TableHead>
                                    {/* <TableRow>
                                        
                                        {wigInfo.wigImages.map((img,index) => {
                                            
                                            return (<TableCell >Image{index}  </TableCell>)
                                        })}
                                       
                                    </TableRow> */}
                                </TableHead>
                                <TableBody>
                                    {wigInfo.wigImages && <TableRow >
                                        {wigInfo.wigImages.map((img) => {

                                            return (<TableCell >
                                                <img
                                                    width={'50px'}
                                                    // height={'60%'}
                                                    //   key={index}
                                                    src={img}
                                                    alt="Uploaded Image"


                                                />
                                            </TableCell>)
                                        })}

                                    </TableRow>}
                                </TableBody>
                                {/* <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody> */}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}




export default function CollapsibleTable(props) {
    const [userId, setUserId] = useState('')
    const [rows, setRows] = useState([])
    useEffect(() => {
        console.log('ping', props)
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                console.log(user.uid, 'uid')
                setUserId(user.uid)
                const q = query(collection(firestore, "sellers/" + user.uid + "/wigs"), where("displayToUser", "==", true));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const trows = [];
                    querySnapshot.forEach((doc) => {
                        const wig = doc.data()
                        console.log('show me wig', wig)
                        trows.push(
                            createData(
                                wig?.wigImages[0] || '',
                                doc.id,
                                wig.wigName || 'None',
                                wig.wigReviewStatus || 'PENDING',
                                wig.listingStatus || 'PENDING',
                                wig.deliveryStatus || 'PENDING',
                                wig.salePrice,
                                wig.wigProcessingStatus || 'PENDING',

                                wig

                            )


                        );
                    });
                    setRows(trows)
                    if (trows.length == 0 || trows == undefined) {
                        props.setTableIsEmpty()

                    }
                    console.log("Current cities in CA: ", trows);
                });


                // ...
            } else {

                // User is signed out
                // ...
            }
        });


    }, [])



    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell />

                        <TableCell>Wig ID</TableCell>
                        <TableCell>Wig Name</TableCell>
                        {/* <TableCell >Wig Approval Status</TableCell> */}
                        <TableCell >Wig Status</TableCell>
                        {/* <TableCell >Delivery Status</TableCell> */}
                        <TableCell >Listing Status</TableCell>
                        <TableCell align="center" >Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} userId={userId} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
