import React, { useState, useEffect } from "react";
import { storage } from "../Services/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function ImageUpload(props) {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);


  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const storageRef = ref(storage, 'wig');


    setImages(
      images.concat(
        Array.from(event.target.files).map((file) => { return ({ file: file, url: URL.createObjectURL(file) }) })
      )
    );



    files.forEach((file, i) => {
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
        });


      });
      //   const fileRef = storageRef.child(file.name);
      //   fileRef.put(file).then((snapshot) => {
      //     fileRef.getDownloadURL().then((url) => {
      //       setImages(images.concat(url));
      //     });
      //   });
    });
    // props.addImages(tempStore)


  };

  useEffect(() => {
    props.addImages(images)
  }, [images])

  const handleRemoveImage = (image) => {

    setImages(images.filter((item) => item !== image));
    // props.addImages(images)

  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleExitClick = () => {
    setSelectedImage(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target.className === "modal-background") {
      setSelectedImage(null);
    }
  };

  return (
    <div>
      <Box component={'span'} style={{ display: "flex", alignItems: "center", alignSelf: "center" }}>
         
      <label htmlFor="fileInput" style={{ display: "inline-block" }}>
        <Button variant="outlined" component="span" color="primary">
        Upload 4 Images Of Your Wig Here
        </Button>
      </label>
      
      <input id="fileInput" type="file" onChange={handleImageChange} multiple style={{ display: "none" }} />
      </Box>
      <div style={{ display: "flex", overflowX: "scroll" }}>
        {images.map((image, index) => (
          <>

            <div key={index} >
              <Box style={{ display: "flex", alignItems: "center", alignSelf: "center" }}>
                <Stack>
                  <Box m={2}>
                    <Button onClick={() => handleRemoveImage(image)} variant="contained" color="error">
                      Remove
                    </Button>
                    {/* <button onClick={() => handleRemoveImage(image)}>Remove</button> */}

                  </Box>
                  <Box m={2}>
                    <img
                      width={'99%'}
                      height={'99%'}
                      key={index}
                      src={image.url}
                      alt="Uploaded Image"
                      onClick={() => handleImageClick(image.url)}
                      style={{ marginRight: "10px" }}
                    />
                  </Box>
                </Stack>
              </Box>


            </div></>
        ))}
      </div>
      {selectedImage && (
        <div
          onClick={handleOutsideClick}
          className="modal-background"
          style={{ position: "fixed", top: "0", left: "0", bottom: "0", right: "0", background: "rgba(0,0,0,0.5)" }}
        >
          <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <img src={selectedImage} width={'90%'} length={"90%"} alt="Selected Image" />
            <button onClick={handleExitClick} style={{ position: "absolute", top: "10px", right: "10px" }}>
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
