import React from 'react';
import './Footer.css'; // Import the CSS file for the component
import { redirect, Link } from "react-router-dom";
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box } from '@mui/material';
import moment from 'moment';
function Footer() {
    return (
        <div className='footer-container' >

            <Grid2 sm={12} direction={'row'}>
                {/* <Grid2 sm={4} className={'footerMiddle'} >
                    <Box>
                        <p>Hair Again</p>
                    </Box>

                </Grid2> */}
                <Grid2 sm={4}   >
                    <div className={'footerMiddle'}>
                        <p>&copy; {moment().format('YYYY')} Hair Again. All rights reserved.</p>
                    </div>
                    <div className={'footerRight bottomLink'}>
                        <Box>
                            <Link to={'/homepage'}>Home</Link>
                        </Box>
                        <Box>
                            <Link to={'/faq'}>FAQ</Link>
                        </Box>
                        <Box>
                            <Link to={'/how-it-works'}>Selling Your Used Wigs</Link>
                        </Box>
                        <Box>
                            <Link to={'/how-it-works-wig-maker'}>Becoming A Seller On Hair Again</Link>
                        </Box>
                        <Box>
                            <Link to={'/about-us'}>About Us</Link>
                        </Box>
                        <Box>
                            <Link to={'/terms-of-service'}> Terms Of Service </Link>
                        </Box>
                        <Box>
                            <Link to={'/privacy-policy'}> Privacy Policy </Link>
                        </Box>
                    </div>
                </Grid2>

            </Grid2>



        </div>
    );
}

export default Footer;
