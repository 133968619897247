import React, { useState,useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import EmailIcon from '@mui/icons-material/Email';
import Face2Icon from '@mui/icons-material/Face2';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Footer from "../../../shared/Footer";
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {  logEvent } from "firebase/analytics";
import {analytics } from '../../../Services/firebase'

function HowItWorks() {
    const [toggleWigView, setToggleWigView] = useState(false)

    useEffect(()=>{
        logEvent(analytics,'on_how_it_works')
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'How it Works', 
          });
    },[])

    return (<>
        {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
        <Grid container display="flex" justifyContent="center" alignItems="center" >
            <Grid  >
                {/* <Typography variant="h3" align="center" gutterBottom>Selling On Hair Again!</Typography> */}
                {/* Step 1 */}
                <>
                    <Box m={2}  >

                        <Divider>
                            <Chip label="Step 1" />
                        </Divider>
                    </Box>

                    <Box m={2} >
                        <Card style={{ maxWidth: "500px" }}>
                            <Box m={3} display="flex" justifyContent="center" alignItems="center">
                                <AddCircleIcon fontSize="large" />
                                {/* <img width={'70%'} height={'60%'} src={"../HairAgainLogo.png"} /> */}
                                {/* <Typography variant="h5" align="center" gutterBottom>Step 1</Typography> */}
                            </Box>
                            <CardContent>
                                <Typography variant="h4" align="center" gutterBottom>Add Your Wigs</Typography>
                                <p style={{ textAlign: 'start' }} >To get started, you need to add information about the wig you want to sell.  </p>
                                <p style={{ textAlign: 'start' }}  > This includes uploading clear images of the wig from different angles and providing detailed information about the wig's condition, including any damages or alterations. </p>
                                <p style={{ textAlign: 'start' }} >  Our team will then review your wig information to see if it meets our requirements for selling on our platform.
                                </p>

                            </CardContent>
                        </Card>
                    </Box>
                </>

                {/* Step 2 */}
                <>
                    <Box m={2} mt={5} >

                        <Divider>
                            <Chip label="Step 2" />
                        </Divider>
                    </Box>

                    <Box m={2} >
                        <Card style={{ maxWidth: "500px" }}>
                            <Box m={3} display="flex" justifyContent="center" alignItems="center">
                                <SearchIcon fontSize="large" />

                            </Box>
                            <CardContent>
                                <Typography variant="h4" align="center" gutterBottom>Review</Typography>
                                <p style={{ textAlign: 'start' }}>Once you submit your wig information, our team will review it and respond within 24 hours. </p>
                                <p style={{ textAlign: 'start' }}>If your wig meets all the requirements, we will notify you that your wig is ready to be listed on our platform for sale. </p>
                                <p style={{ textAlign: 'start' }}>If there are any issues with your submission, we will provide feedback and suggestions on how to improve your listing.</p>
                            </CardContent>
                        </Card>
                    </Box>
                </>

                {/* Step 3 */}
                <>
                    <Box m={2} mt={5} >

                        <Divider>
                            <Chip label="Step 3" />
                        </Divider>
                    </Box>

                    <Box m={2} >
                        <Card style={{ maxWidth: "500px" }}>
                            <Box m={3} display="flex" justifyContent="center" alignItems="center">
                                <LocalShippingIcon fontSize="large" />
                            </Box>
                            <CardContent>
                                <Typography variant="h4" align="center" gutterBottom>Ship Your Wig</Typography>

                                <p style={{ textAlign: 'start' }}> We require all wigs to be shipped to our facilities for cleaning and sterilization before they can be listed for sale on our platform. </p>
                                <p style={{ textAlign: 'start' }}>We will send you pre-paid shipping tags that you can take to the nearest shipping provider.</p>
                                <p style={{ textAlign: 'start' }}>  We also require you to provide a video of yourself placing the wig into the shipping bag for verification purposes.</p>



                            </CardContent>
                        </Card>
                    </Box>
                </>

                {/* Step 4 */}
                <>
                    <Box m={2} mt={5} >

                        <Divider>
                            <Chip label="Step 4" />
                        </Divider>
                    </Box>

                    <Box m={2} >
                        <Card style={{ maxWidth: "500px" }}>
                            <Box m={3} display="flex" justifyContent="center" alignItems="center">
                                <DomainVerificationIcon fontSize="large" />
                            </Box>
                            <CardContent>
                                <Typography variant="h4" align="center" gutterBottom>Listing</Typography>
                                
                                <p style={{ textAlign: 'start' }}> Once your wig arrives at our facilities and is processed, we will list it on our platform for sale.</p>
                                <p style={{ textAlign: 'start' }}> We will notify you via your wig dashboard and email when your wig is available for purchase by millions of potential buyers.</p>
                                  

                            </CardContent>
                        </Card>
                    </Box>
                </>

                {/* Step 5 */}
                <>
                    <Box m={2} mt={5} >

                        <Divider>
                            <Chip label="Step 4" />
                        </Divider>
                    </Box>

                    <Box m={2} >
                        <Card style={{ maxWidth: "500px" }}>
                            <Box m={3} display="flex" justifyContent="center" alignItems="center">
                                <MonetizationOnIcon fontSize="large" />
                            </Box>
                            <CardContent>
                                <Typography variant="h4" align="center" gutterBottom>Sold</Typography>
                                
                                <p style={{ textAlign: 'start' }}>If your wig is sold, we will notify you immediately, and the wig will be de-listed from our platform. </p>
                                <p style={{ textAlign: 'start' }}>You will receive payment via any payment app of your choosing. </p>
                                <p style={{ textAlign: 'start' }}>If your wig does not sell within a certain time frame, you may choose to relist it or have it returned to you at your expense.</p>
                                   

                            </CardContent>
                        </Card>
                    </Box>
                </>


            </Grid>
            {/* <Grid  xs={12}>
                
                    <Card >
                        <Typography variant="h3" align="center" gutterBottom>Welcome !</Typography>

                    </Card>
                
            </Grid> */}
        </Grid>

    </>
        //  </Box>
    );
}

export default HowItWorks;
