export{
wigTypes,
hairType,
brand,
condition,
hairOriginLookup,
laceTypeLookup,
trimming,
densityLookup,
reasonForSelling,
wigListingStatus,
wigApprovalStatus,
deliveryStatus,
wigStatus
}

const wigTypes=[

]

const hairType=[
    { value: 'topper', label: 'Topper' },
    { value: 'wig', label: 'Wig' },
    { value: 'extensions', label: 'Extensions' },
    { value: 'ponyTopper', label: 'Pony Topper' },
    { value: 'ponyActiveWig', label: 'Pony/Active Wig' },
    { value: 'other', label: 'Other' },
]

const brand=[
{value:"highlineWigs",label:"Highline Wigs"},
{value:"lusta",label:"Lusta"},
{value:"wigsByTiffani",label:"Wigs by Tiffani"},
{value:"theHairMama",label:"The Hair Mama"},
{value:"jonRenau",label:"Jon Renau"},
{value:"stackedHair",label:"Stacked Hair"},
{value:"lilyHair",label:"Lily's Hair"},
{value:"saryWigs",label:"Sary Wigs"},
{value:"gardeaux",label:"Gardeaux"},
{value:"madisonWigs",label:"Madison Wigs"},
{value:"milano",label:"Milano"},
{value:"luWigs",label:"Lu's Wigs"},
{value:"hairalicious",label:"Hairalicious"},
{value:"other",label:"Other"},



]

const condition = [
    {value:"new",label:"New (Never worn, only tried on, no alterations)"},
    {value:"likeNew",label:"Like New (Lightly worn, still looks new)"},
    {value:"used",label:"Used (Well loved, but has a lot of life left)"},
]

const hairOriginLookup = [
    { countryName: 'India', value: 'indian', label: 'Indian', checked: false },
    { countryName: 'China', value: 'chinese', label: 'Chinese', checked: false },
    { countryName: 'Brazil', value: 'brazilians', label: 'Brazilian', checked: false },
    { countryName: 'Mongolia', value: 'mongolian', label: 'Mongolian', checked: false },
    { countryName: 'Malaysia', value: 'malaysian', label: 'Malaysian', checked: false },
    { countryName: 'Peru', value: 'peruvian', label: 'Peruvian', checked: false },
    { countryName: 'Synthetic', value: 'synthetic', label: 'Synthetic', checked: false },
    { countryName: 'syntheticHeatResistant', value: 'syntheticHeatResistant', label: 'Synthetic Heat Resistant', checked: false },
    { countryName: 'Other', value: 'other', label: 'Other', checked: false },

]

const laceTypeLookup = [
    { value: 'hdLaceWig', label: 'HD Lace Wig' },
    { value: 'silkTop', label: 'Silk Top' },
    { value: 'laceFrontRegular', label: 'Lace Front Regular' },
    { value: 'None', label: 'None' },
    { value: 'Other', label: 'Other' },
]

const trimming=[
    { value: 'trimmed', label: 'Has A Trimmed Lace Front' },
    { value: 'unTrimmed', label: 'Has A Untrimmed Lace Front' },
    { value: 'noLaceFront', label: 'Has No Lace Front' },
    { value: 'Other', label: 'Other' },
]

const densityLookup = [
    { value: '50', label: 'Extra Light 50%' },
    { value: '80', label: 'Light 80%' },
    { value: '120', label: 'Medium 120% ' },
    { value: '130', label: 'Medium Heavy 130%' },
    { value: '150', label: 'Heavy 150%' },
    { value: '180', label: 'Extra Heavy 180%' },
    { value: 'unknown', label: 'Unknown' },
    { value: 'Other', label: 'Other' },
]

const reasonForSelling = [
    { value: 'noLongerNeeded', label: 'No Longer Needed' },
    { value: 'notTheRightSize', label: 'Not The Right Size' },
    { value: 'wrongColor', label: 'Wrong Color ' },
    { value: 'wrongLength', label: 'Wrong Length' },
    { value: 'notWhatYouExpected', label: 'Not What You Expected' },
    { value: 'iAmWigSeller', label: 'Im A Wig Maker Selling My Wig' },
   
]

const wigListingStatus =[
    { value: 'NOT_LISTED', label: 'Not Listed' },
    { value: 'LISTED', label: 'Listed' },
    { value: 'WILL_BE_LISTED', label: 'Will Be Listed Soon' },
    { value: 'UNLISTED', label: 'Not Listed' },
]

const wigApprovalStatus =[
    { value: 'PENDING_REVIEW', label: 'Will Be Reviewed Soon' },
    { value: 'IN_PROGRESS', label: 'Currently Being Reviewed' },
    { value: 'APPROVED', label: 'Your Wig Is Approved For Listing' },
    { value: 'NOT_APPROVED', label: 'Your Wig Does Not Meet The Requirements' },
    { value: 'PENDING', label: 'Will Be Reviewed Soon' },
]

const deliveryStatus =[
    { value: 'NO_BUYER', label: 'Waiting For Buyer' },
    { value: 'SHIPPING_TAGS_NOT_READY', label: 'Shipping Tags Are Not Available Until Approved' },
    { value: 'SHIPPING_TAGS_READY_CHECK_EMAIL', label: 'Check Your Email For Shipping Tags' },
    { value: 'WAITING_FOR_SHIPMENT', label: 'Waiting For You To Ship Wigs' },
    { value: 'RECEIVED_SHIPMENT', label: 'Your Wig Has Been Received' },
    { value: 'WIG_SHIPPED_TO_CLIENT', label: 'Your Wig Has Been Shipped To Client' },
    { value: 'PENDING', label: 'Shipping Tags Are Not Available Until Approved' },
]

//wig processing Status
const wigStatus =[
    
    { value: 'NOT_SOLD', label: 'Wig Has Not Been Sold' },
    { value: 'SOLD', label: 'Wig Has Been Sold' },
    { value: 'PENDING', label: 'Wig Has Not Been Sold' },
]
