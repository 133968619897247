import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../Services/firebase'

function PrivacyPolicy() {
    const [toggleWigView, setToggleWigView] = useState(false)
    useEffect(() => {
        logEvent(analytics, 'PrivacyPolicy')
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'PrivacyPolicy',
        });
    }, [])

    return (<>
        {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
        <Grid container xs={12} padding={3} display="flex" justifyContent="center" alignItems="center">
            <div>
                <h1>Privacy Policy for Hair Again</h1>
                <p>Hair Again ("us", "we", or "our") operates the https://www.hairagain.com website (the "Service").</p>
                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
                <h2>Information Collection and Use</h2>
                <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                <h3>Types of Data Collected</h3>
                <p>Personal Data</p>
                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                    <li>Cookies and Usage Data</li>
                </ul>
                <p>Usage Data</p>
                <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <h3>Use of Data</h3>
                <p>Hair Again uses the collected data for various purposes:</p>
                <ul>
                    <li>To provide and maintain our Service</li>
                    <li>To notify you about changes to our Service</li>
                    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                    <li>To provide customer support</li>
                    <li>To gather analysis or valuable information so that we can improve our Service</li>
                    <li>To monitor the usage of our Service</li>
                    <li>To detect, prevent and address technical issues</li>
                    <li>To send you promotional offers or marketing messages</li>
                </ul>
                <h2>Disclosure of Data</h2>
                <p>We may disclose your Personal Data to third-party service providers who perform services on our behalf or to our affiliates who provide services to you. We may also disclose your Personal Data if we are required to do so by law or in response to valid requests by public authorities.</p>
                <h2>Security of Data</h2>
                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                <h2>Links to Other Sites</h2>
                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site
                    you visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                <h2>Children's Privacy</h2>
                <p>Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
                <h2>Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                <ul>
                    <li>By email: info@hairagainshop.com</li>
                    {/* <li>By phone: [insert phone number]</li>
                    <li>By mail: [insert mailing address]</li> */}
                </ul>
            </div>
        </Grid>

    </>
        //  </Box>
    );
}

export default PrivacyPolicy;
