
import React, { useState, useEffect } from "react";
import { auth, firestore } from '../Services/firebase'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, doc, setDoc, Timestamp } from "firebase/firestore";
import { useNavigate, useParams, redirect } from "react-router-dom";

import firebase from "firebase/compat/app"

export default function FirebaseLoginComponent() {

    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    const navigation = useNavigate();
    var uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: async function (authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                console.log('Yep Im signed IN!!', authResult)
                const storedAffiliateLink = localStorage.getItem("affiliateLink");
                // await addDoc(collection(firestore, "sellers", userId, 'affiliates'),storedAffiliateLink );
                navigation('/dashboard/viewWigs')



                return true;
            },
            uiShown: function () {
                // The widget is rendered.
                // Hide the loader.
                document.getElementById('loader').style.display = 'none';
            }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: '/dashboard/viewWigs',
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: true,
                fullLabel: "Continue With Email",
                
            },
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                customParameters: {
                    // Forces account selection even when one account
                    // is available.
                    prompt: 'select_account'
                },
                fullLabel: "Continue with Google",
                scope:['profile','openid','email']
                    
                

            },
            // {
            //     provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            //     scopes: [
            //         'public_profile',
            //         'email',
            //         'user_likes',
            //         'user_friends'
            //       ],
            // }

        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
    };

    useEffect(() => {
        ui.start('#firebaseui-auth-container', uiConfig);


    })

    return (<>
        <div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>

    </>)

}