import logo from './logo.svg';
import './App.css';
import LoginPage from './Auth/LoginPage';
import { Routes, Route, Outlet, Link, Router } from "react-router-dom";
import Layout from './routes/Layout';
import AdminComponent from './adminComponents/AdminComponent';
import main from '.'
import ViewWigs from './adminComponents/pages/Wigs/ViewWigs';
import AddWig from './adminComponents/pages/Wigs/AddWig';
import WaitList from './adminComponents/pages/WaitList/WaitList';
import Referrals from './adminComponents/pages/Referral/Referrals';
import AddReferral from './adminComponents/pages/Referral/AddReferral';
import ViewReferral from './adminComponents/pages/Referral/ViewReferral';
import AddAffiliateLink from './adminComponents/pages/Referral/AddAffiliateLink';
import Footer from './shared/Footer';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HowItWorks from './adminComponents/pages/infoPages/HowItWorks';
import FaqPage from './adminComponents/pages/infoPages/FaqPage';
import AboutUs from './adminComponents/pages/infoPages/AboutUs';
import HowItWorksWigMaker from './adminComponents/pages/infoPages/HowItWorksWigMaker';
import Homepage from './adminComponents/pages/infoPages/Homepage';
import WigMakers from './adminComponents/pages/infoPages/WigMakers';
import SecondHandSellers from './adminComponents/pages/infoPages/SecondHandSellers';
import PrivacyPolicy from './adminComponents/pages/infoPages/PrivacyPolicy';
import TermsOfService from './adminComponents/pages/infoPages/TermsOfService';
import Orders from './adminComponents/pages/Orders/Orders';
function App() {
  return (
    <>
      <div className='AppHandler'>


        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="*" element={<Layout />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="homepage" element={<Homepage />} />
            <Route path="wigmakers" element={<WigMakers />} />
            <Route path="sellers" element={<SecondHandSellers />} />
            <Route path="how-it-works-wig-maker" element={<HowItWorksWigMaker />} />
            <Route path="login" element={<LoginPage />} />
            <Route path='how-it-works' element={<HowItWorks/>}/>
            <Route path='faq' element={<FaqPage/>}/>
            <Route path='affiliate/:affiliateId' element={<AddAffiliateLink />} />
            <Route path='dashboard/*' element={<AdminComponent />} >
            <Route path='how-it-works' element={<HowItWorks/>}/>
            <Route path='faq' element={<FaqPage/>}/>
            <Route path='orders' element={<Orders/>}/>
            <Route path="about-us" element={<AboutUs />} />
              <Route path='referrals/*' element={<Referrals />} >
                <Route path='addReferral' element={<AddReferral />} />
                <Route path='referral' element={<ViewReferral />} />
              </Route>
              <Route path='addWig' element={<AddWig />} />
              <Route path='viewWigs' element={<ViewWigs />} />
            </Route>
          </Route>
        </Routes>

        <Footer />
        <ToastContainer />
      </div>




      {/* </Router> */}
      {/* http://localhost:3000/affiliate?affiliate=WRjvyzBsumdfItgKoiqCns7dSRH3 */}
      {/* http://localhost:3000/affiliate/WRjvyzBsumdfItgKoiqCns7dSRH3 */}
      {/* http://localhost:3000/referrals?affiliate=WRjvyzBsumdfItgKoiqCns7dSRH3 */}
    </>
  );
}

export default App;
