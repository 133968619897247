import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, addDoc, doc, setDoc, arrayUnion, updateDoc } from "firebase/firestore";
import { auth, firestore, firebase } from '../../../Services/firebase'
import { useNavigate, useParams, redirect } from "react-router-dom";

function AddAffiliateLink() {
  const [affiliateLink, setAffiliateLink] = useState("");
  const navigation = useNavigate();
  const { affiliateId } = useParams();

  useEffect(() => {
    console.log('who da best left guard')
  })

  useEffect(() => {

    console.log('affiliateLink', affiliateLink)
    console.log('affiliateId', affiliateId)

    if (affiliateId) {
      // Set affiliate link in state and local storage
      setAffiliateLink(affiliateId);
      localStorage.setItem("affiliateId", affiliateId);
      console.log('affiliateId if statement', affiliateId)
      // redirect("/login/"+affiliateId)


    } else {

      // Check local storage for affiliate link
      const storedAffiliateId = localStorage.getItem("affiliateId");

      console.log('checking the affilaite', storedAffiliateId)
      if (storedAffiliateId) {
        setAffiliateLink(storedAffiliateId);
      }
    }
  }, [affiliateLink]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && affiliateLink !== "") {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log('havin a blasttt with affilits', affiliateLink)


        if (uid !== affiliateLink) {
          console.log('Sellers affilate link', affiliateLink)

           // add the referr id information
           await setDoc(doc(firestore, "sellers", uid), {

            referredBy: affiliateLink
          });

          // add person the current user to another another user reffer list
          await setDoc(doc(firestore, "sellers", affiliateLink), {
            usersReferred: arrayUnion({ 
              userId: uid,
              wigsListed:0,
              wigsSold:0,
              percentageOfSale:10,
              moneyYouMade:0
             })
          }, { merge: true });

         


        }
        localStorage.removeItem('affiliateId')
        // ...
      } else {

        // User is signed out
        // ...

      }
    })
  }, [affiliateLink])

  return <></>;
}

export default AddAffiliateLink;
