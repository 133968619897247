import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AffiliateLinkGenerator from "./AffiliateLinkGenerator";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import { collection, query, where, onSnapshot, doc, getDoc } from "firebase/firestore";
import { auth, firestore, analytics } from '../../../Services/firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { logEvent } from "firebase/analytics";

function Referrals() {
  const [referred, setReferred] = useState([])


  useEffect(() => {
    logEvent(analytics, 'on_referrals')
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Referrals',
    });
  }, [])

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const docRef = doc(firestore, "sellers", uid);
        // const docSnap = await getDoc(docRef);
        // const q = query(collection(firestore, "sellers/" + uid + "/wigs"));
        console.log('let me check ur shoil', uid)
        const unsubscribe = onSnapshot(docRef, (document) => {

          const data = document.data()
          console.log('show me data for reffers', data)

          if (data.usersReferred) {
            setReferred(data.usersReferred)
          }



        });
      }

    })



  })

  return (
    <>
      {/* <nav>
        <Link to="referral">Referrals </Link>/
        <Link to="addReferral">Add referral </Link>/
        
      </nav> */}

      
      <Card>
        {referred != undefined && referred != 0 &&<>
        <CardContent>
          <AffiliateLinkGenerator />
        </CardContent>
        </>}
      </Card>
      <Card>
        <CardContent>
        {referred != undefined && referred != 0 &&<>
           <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Referral Number</TableCell>
                  <TableCell>Percentage Of Wig Sales</TableCell>
                  <TableCell>Wigs Listed</TableCell>
                  <TableCell>Wigs Sold</TableCell>
                  <TableCell>Money You Made</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {referred.map((data, index) => {

                  return (
                    <TableRow key={index} >
                      <TableCell>
                        {data.userId}
                      </TableCell>
                      <TableCell>
                        {data.percentageOfSale + '%'}
                      </TableCell>
                      <TableCell>
                        {data.wigsListed}
                      </TableCell>
                      <TableCell>
                        {data.wigsSold}
                      </TableCell>
                      <TableCell>
                        {'$' + data.moneyYouMade}
                      </TableCell>
                    </TableRow>
                  )

                })}

              </TableBody>
            </Table>
          </TableContainer>  
          </>}
          
          
          {referred.length == 0 &&
            <>
              {/* <h2>Share your affiliate link you get 10% from every sale from your referral</h2> */}
              <Grid mb={10} xs={12} direction={'column'} textAlign={'center'}   >

                {/* <h1>Welcome To Hair Again!</h1> */}
                <Grid display="flex" justifyContent="center" alignItems="center">
                  <Box id='stepper'> <img width={'70%'} src={"../welcomeReferral.png"} /></Box>
                </Grid>
                <h2>Share your affiliate link you get 20% from every sale from your referral</h2>
                <AffiliateLinkGenerator />
              </Grid>

            </>}
        </CardContent>
      </Card>
      {/* <Outlet/> */}
    </>
  );
}

export default Referrals;