import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../Services/firebase'

function TermsOfService() {
    const [toggleWigView, setToggleWigView] = useState(false)
    useEffect(() => {
        logEvent(analytics, 'TermsOfService')
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'TermsOfService',
        });
    }, [])

    return (<>
        {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
        <Grid container xs={12} padding={3} display="flex" justifyContent="center" alignItems="center">
            <div>
                <h1>Terms of Service for Hair Again</h1>
                <p>Welcome to Hair Again, an online marketplace connecting wig-makers with customers. By accessing or using our website, you agree to be bound by the following terms and conditions ("Terms of Service"). If you do not agree to these terms, you may not use our website.</p>
                <h2>1. Use of Hair Again</h2>
                <p>You must be at least 18 years old to use our website. You may not use our website for any illegal or unauthorized purpose. You may not use our website to harass, abuse, or harm another person or to collect, store, or share personal information about others.</p>
                <h2>2. Selling on Hair Again</h2>
                <p>As a seller on Hair Again, you are responsible for creating accurate listings and fulfilling orders promptly. You must accurately describe your wigs and include high-quality photos. You must ship your wigs promptly and provide excellent customer service. Hair Again reserves the right to remove any listings that violate our policies.</p>
                <h2>3. Buying on Hair Again</h2>
                <p>As a buyer on Hair Again, you are responsible for reading the full listing description before making a purchase. You must provide accurate shipping information and pay for your purchase promptly. Hair Again is not responsible for any disputes between buyers and sellers.</p>
                <h2>4. Fees and Payments</h2>
                <p>Hair Again charges a fee for each sale made on our website. This fee is a percentage of the sale price and is subject to change. Sellers must provide accurate payment information and pay all fees owed to Hair Again promptly.</p>
                <h2>5. Intellectual Property</h2>
                <p>All content on our website, including text, graphics, logos, images, and software, is the property of Hair Again or its licensors and is protected by United States and international copyright laws. You may not copy, reproduce, or distribute any content from our website without our express written permission.</p>
                <h2>6. Limitation of Liability</h2>
                <p>Hair Again is not responsible for any damages or losses that may result from the use of our website. We do not guarantee the accuracy or completeness of any content on our website. Hair Again is not responsible for any errors, omissions, or inaccuracies in any content on our website.</p>
                <h2>7. Indemnification</h2>
                <p>You agree to indemnify and hold harmless Hair Again, its directors, officers, employees, and agents from and against any claims, actions, damages, or liabilities, including reasonable attorneys' fees, arising from your use of our website or your violation of these Terms of Service.</p>
                <h2>8. Changes to Terms of Service</h2>
                <p>Hair Again reserves the right to modify these Terms of Service at any time. Your continued use of our website after any such changes will constitute your acceptance of the modified Terms of Service.</p>
                <h2>9. Governing Law</h2>
                <p>These Terms of Service and any disputes arising from your use of our website will be governed by and construed in accordance with the laws of the State of California, without regard to its conflicts of law provisions.</p>
                <p>If you have any questions about these Terms of Service, please contact us at info@hairagainshop.com .</p>
            </div>
        </Grid>

    </>
        //  </Box>
    );
}

export default TermsOfService;
