import React, { useState,useEffect } from "react";
import { auth, firestore, firebase,analytics } from '../../../Services/firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
import { logEvent } from "firebase/analytics";

import Button from '@mui/material/Button';
function AffiliateLinkGenerator() {
  const [affiliateLink, setAffiliateLink] = useState("");
    const [userId,setUserId] = useState("")
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        setUserId(user.uid)

        
    
      } else {

        
      }
    });


  }, [])

  const generateAffiliateLink = () => {
    // Get current URL
    const currentUrl = window.location.href.replace('/dashboard/referrals','/affiliate');
    console.log('imma finish that toningt',currentUrl)
    logEvent(analytics, 'User Generated A Link')


    // Generate affiliate link by appending query string parameter
    const affiliateLink = `${currentUrl}/${userId}`;

    // Set affiliate link in state
    setAffiliateLink(affiliateLink);
    toast.success(' Your Affiliate Link Has Been Generated')
  };

  const copyAffiliateLink = () => {
    // Copy affiliate link to clipboard
    navigator.clipboard.writeText(affiliateLink);
    toast.success(' Copied! You can begin sharing your link!')
    logEvent(analytics, 'User Copied Generated Link')
  };

  return (
    <div>
      <Button variant="contained"  color="secondary" onClick={generateAffiliateLink}>Generate Affiliate Link</Button>
      {affiliateLink && (
        <>
          <p>{affiliateLink}</p>
          <Button variant="contained"  onClick={copyAffiliateLink}>Copy to Clipboard</Button>
        </>
      )}
    </div>
  );
}

export default AffiliateLinkGenerator;
