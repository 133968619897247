import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { InputAdornment, Typography } from "@mui/material";
import { useController, useForm, Controller } from "react-hook-form";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ImageUpload from "../../../shared/ImageUpload";
import { auth, firestore, firebase, storage, analytics } from '../../../Services/firebase'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, doc, setDoc, Timestamp, updateDoc, arrayUnion } from "firebase/firestore";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDownloadURL, ref, uploadBytes, uploadString } from "firebase/storage";
import {
  wigTypes,
  hairType,
  brand,
  condition,
  hairOriginLookup,
  laceTypeLookup,
  trimming,
  densityLookup,
  reasonForSelling,
  wigListingStatus,
  wigApprovalStatus,
  deliveryStatus,
  wigStatus
} from "./WigsLookupFile";
import moment from "moment";
import { toast } from "react-toastify";
import { yupResolver } from '@hookform/resolvers/yup';
import { logEvent } from "firebase/analytics";
import * as yup from "yup";

function AddWig() {

  const navigate = useNavigate();
  const [userId, setUserId] = useState('')
  const [images, setImages] = useState([])

  useEffect(() => {
    logEvent(analytics, 'user adding wig') 
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'adding_wig_screen',
    });
  }, [])


  useEffect(() => {
    const time = moment().format()
    console.log(moment().format(), 'unix check')
    console.log(moment(time).unix(), 'back to momment check')
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        setUserId(user.uid)

        navigate('/dashboard/addWig')
        logEvent(analytics, 'user clicked signed in')
        // ...
      } else {


      }
    });


  }, [])

  const addImages = (imgs) => {
    setImages(imgs)
    console.log('imags added', imgs)
    logEvent(analytics, 'user added an images')
  }


  const validationSchema = yup.object({
    hairType: yup.string().required('Please Select One'),
    ageOfHair: yup.date().default(() => new Date()),
    hairCondition: yup.string().required('Please Select One'),
    purchasePrice: yup.number().positive().integer().required('How Much Did You Pay For Hair Piece'),
    salePrice: yup.number().positive().integer().required('How Much Do You Want To Sell Your Hair Piece For'),
    hairBrand: yup.string().required('Please Select One'),
    hairLength: yup.string().required('Please Give Hair Length'),
    hairOrigin: yup.string().required('Please Select One'),
    wigCapType: yup.string().required('Please Select One'),
    wigTrimming: yup.string().required('Please Select One'),
    hairDensity: yup.string().required('Please Select One'),
    wigDimensions: yup.string().required('Please Select One'),
    reasonForSelling: yup.string().required('Please Select One'),
    wigColor: yup.string().required('Add the color of your wig')
    // wigImages: yup.array.

  }).required();

  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      hairType: 'wig',
      hairBrand: '',
      wigName: '',
      ageOfHair: moment().format(),
      hairCondition: '',
      purchasePrice: 0,
      salePrice: 0,
      hairLength: '',
      hairOrigin: '',
      wigCapType: '',
      wigTrimming: '',
      hairDensity: '',
      wigDimensions: '',
      reasonForSelling: '',
      wigImages: [],
      extraDetails:'',
      hairBrandOther:'',
      wigColor:''
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async data => {
    var saveIsSucess = false;
    console.log('chcking hair', data)
    logEvent(analytics, 'user attempted to submit wig')
    const userWig = {
      ...data,
      ageOfHair: moment(data.ageOfHair).utc().unix(),
      wigReviewStatus: wigApprovalStatus[0].value,
      listingStatus: wigListingStatus[0].value,
      deliveryStatus: deliveryStatus[0].value,
      wigProcessingStatus: wigStatus[0].value,
      displayToUser: true,
      modifiedDate: moment().utc(true).unix(),
      dateAdded: moment().utc(true).unix()

    }
    console.log(moment(data.ageOfHair).utc().unix(), 'ageofhair unix')
    console.log(moment().utc(true).unix(), 'unix')
    console.log('checking img length',images.length)
    if (images.length >= 4) {

      await addDoc(collection(firestore, "sellers", userId, 'wigs'), userWig).then((data) => {
        saveIsSucess = true
        const docId = data.id;
        const currentDoc = doc(firestore, "sellers", userId, 'wigs', docId)

        images.forEach((fileData) => {
          const file = fileData.file
          console.log('let me see file name', file)
          const storageRef = ref(storage, 'sellers/' + userId + '/wigs/' + docId + '/' + file.name);


          if (file.name !== undefined) {
            uploadBytes(storageRef, file).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                updateDoc(currentDoc, {
                  wigImages: arrayUnion(downloadURL)
                })


                // setImages(images.concat(downloadURL));
              });


            });
          }
        });


      }).catch((err) => {
        toast.error('Error Could Not Add Your Wig Please Try Again')
        logEvent(analytics, 'user add wig error')
        saveIsSucess = false
      });

      if (saveIsSucess) {
        toast.success('Your Wig Has Been Added!! It will be reviewed by Hair Again Team!',
          { theme: "colored", position: "top-center", })
        logEvent(analytics, 'success user wig added')
        navigate('/dashboard/viewWigs')
      }

    }
    else {
      toast.error('Add 4 images of your hair piece', { theme: "colored", position: "bottom-center", })
      logEvent(analytics, 'User did not add 4 Images')

    }
  };

  return (
    <>
      <Grid>
        <Box>
          <Card >
            <IconButton aria-label="back" onClick={() => {
              navigate('/dashboard/viewWigs')
            }}>
              <ArrowBackIcon fontSize="large" />
            </IconButton> Back
            <CardContent >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box >
                  <Stack  >

                    {/* Hair type Select */}
                    <Box m={3} >
                      <Stack direction={'column'}>
                        <Grid xs={12} xl={6}>
                          <Controller
                            name="hairType"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">What is your Hair Type?</InputLabel>
                              <Select
                                error={errors?.hairType !== undefined}
                                helperText={errors.hairType ? errors.hairType.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {hairType.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                            }
                          />
                        </Grid>
                        {"other" === watch('hairType') && <Grid xs={12} xl={6}>
                          <Controller
                            name="hairTypeOther"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Hair Piece Type</InputLabel>
                              <TextField

                                // label={'Hair Piece Type'}
                                fullWidth
                                {...field}
                                type="text"

                                variant="outlined"
                              />
                            </>}
                          />
                        </Grid>}

                      </Stack>
                    </Box>

                    {/* {hair name} */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="wigName"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Wig Name</InputLabel>
                              <TextField

                                helperText={'Optional'}
                                label="Wig Name(Optional)"
                                fullWidth
                                {...field}
                                type="text"
                                variant="outlined"


                              />
                            </>}
                          />
                        </Grid>
                      </Stack>
                    </Box>

                    {/* Hair Brand Select */}
                    <Box m={3} >
                      <Stack direction={'column'}>
                        <Grid xs={12} xl={6}>
                          <Controller
                            name="hairBrand"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">What Is The Brand Of Your Hair?</InputLabel>
                              <Select
                                error={errors?.hairBrand !== undefined}
                                helperText={errors.hairBrand ? errors.hairBrand.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {brand.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                            }
                          />
                        </Grid>
                        {"other" === watch('hairBrand') && <Grid xs={12} xl={6}>
                          <Controller
                            name="hairBrandOther"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Hair Brand Name</InputLabel>
                              <TextField
                                // label={'You can provide the instagram handle'}
                                fullWidth
                                {...field}
                                type="text"

                                variant="outlined"
                              />
                            </>}
                          />
                        </Grid>}

                      </Stack>
                    </Box>

                    {/* Hair Condition Select */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="hairCondition"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">What Is The Condition Of Your Hair?</InputLabel>
                              <Select
                                error={errors?.hairCondition !== undefined}
                                helperText={errors.hairCondition ? errors.hairCondition.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {condition.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                            }
                          />
                        </Grid>



                      </Stack>
                    </Box>

                    {/* Hair Date Select */}
                    <Box m={3} >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Stack direction={'row'}>
                          <Grid xs={12}>
                            <Controller
                              name="ageOfHair"
                              control={control}
                              render={({ field }) => <>
                                <InputLabel id="demo-simple-select-helper-label">Date You Purchased the Hair</InputLabel>
                                <MobileDatePicker
                                  disableFuture={true}

                                  inputFormat="MM/DD/YYYY"
                                  {...field}
                                  renderInput={(params) => <TextField fullWidth {...params} />}
                                />

                              </>
                              }
                            />
                          </Grid>



                        </Stack>
                      </LocalizationProvider>
                    </Box>

                    {/* Hair Purchase Price */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="purchasePrice"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Purchase Price</InputLabel>
                              <TextField
                                error={errors?.purchasePrice !== undefined}
                                helperText={errors.purchasePrice ? errors.purchasePrice.message : ''}
                                label={'(How much did you pay for your hair piece?)'}
                                fullWidth
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                InputProps={
                                  { startAdornment: <InputAdornment position="start">$</InputAdornment> }
                                }

                                {...field}
                                type="text"
                                variant="outlined"

                              />
                            </>}
                          />
                        </Grid>
                      </Stack>
                    </Box>

                    {/* Hair Price */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="salePrice"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Sale Price </InputLabel>
                              <TextField
                                error={errors?.salePrice !== undefined}
                                helperText={errors.salePrice ? errors.salePrice.message : ''}
                                label="How much do you want to sell it for?"
                                fullWidth
                                {...field}
                                type="text"
                                variant="outlined"

                                InputProps={
                                  { startAdornment: <InputAdornment position="start">$</InputAdornment> }
                                }
                              />
                            </>}
                          />
                        </Grid>
                      </Stack>
                    </Box>

                    {/* Hair Length */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="hairLength"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Length of your hair</InputLabel>
                              <TextField
                                error={errors?.hairLength !== undefined}
                                helperText={errors.hairLength ? errors.hairLength.message : ''}
                                label={'Indicate inches or centimeters (e.x. 23 in or 40 cm)'}
                                fullWidth
                                {...field}
                                type="text"
                                variant="outlined"
                              />
                            </>}
                          />
                        </Grid>
                      </Stack>
                    </Box>

                    {/* Hair Origin */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="hairOrigin"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Hair Origin</InputLabel>
                              <Select
                                error={errors?.hairOrigin !== undefined}
                                helperText={errors.hairOrigin ? errors.hairOrigin.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {hairOriginLookup.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>}
                          />
                        </Grid>
                      </Stack>
                    </Box>

                    {/* Hair Cap Type Select */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="wigCapType"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">What Is The Cap Type ?</InputLabel>
                              <Select
                                error={errors?.wigCapType !== undefined}
                                helperText={errors.wigCapType ? errors.wigCapType.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {laceTypeLookup.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                            }
                          />
                        </Grid>



                      </Stack>
                    </Box>

                    {/* Wig Trimming Type Select */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="wigTrimming"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Wig Trimming?</InputLabel>
                              <Select
                                error={errors?.wigTrimming !== undefined}
                                helperText={errors.wigTrimming ? errors.wigTrimming.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {trimming.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                            }
                          />
                        </Grid>



                      </Stack>
                    </Box>


                    {/* Wig Hair Density Select */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="hairDensity"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Wig Density?</InputLabel>
                              <Select
                                error={errors?.hairDensity !== undefined}
                                helperText={errors.hairDensity ? errors.hairDensity.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {densityLookup.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                            }
                          />
                        </Grid>



                      </Stack>
                    </Box>

                    {/* Wig Dimensions Select */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="wigDimensions"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Wig Cap Circumference</InputLabel>
                              <TextField
                                error={errors?.wigDimensions !== undefined}
                                helperText={errors.wigDimensions ? errors.wigDimensions.message : ''}
                                label={'e.x. 3x5, 7x8, 7x9, etc.'}
                                fullWidth
                                {...field}
                                type="text"
                                variant="outlined"
                              />
                            </>}
                          />
                        </Grid>

                      </Stack>
                    </Box>

                     {/* Wig Color  */}
                     <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="wigColor"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Wig Color</InputLabel>
                              <TextField
                                error={errors?.wigColor !== undefined}
                                helperText={errors.wigColor ? errors.wigColor.message : ''}
                                label={'e.x. black,Gray,etc'}
                                fullWidth
                                {...field}
                                type="text"
                                variant="outlined"
                              />
                            </>}
                          />
                        </Grid>

                      </Stack>
                    </Box>

                    {/* Reason for Selling Select */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="reasonForSelling"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Why Are You Selling Your Hair Piece</InputLabel>
                              <Select
                                error={errors?.reasonForSelling !== undefined}
                                helperText={errors.reasonForSelling ? errors.reasonForSelling.message : ''}
                                labelId="demo-simple-select-helper-label"
                                fullWidth
                                variant="outlined"
                                {...field}
                              >
                                {reasonForSelling.map((data) => (
                                  <MenuItem
                                    key={data.value}
                                    value={data.value}
                                  >
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                            }
                          />
                        </Grid>
                      </Stack>
                    </Box>

                    {/* Extra Details */}
                    <Box m={3} >
                      <Stack direction={'row'}>
                        <Grid xs={12}>
                          <Controller
                            name="extraDetails"
                            control={control}
                            render={({ field }) => <>
                              <InputLabel id="demo-simple-select-helper-label">Extra Details(Optional)</InputLabel>
                              <TextField
                                // error={errors?.extraDetails !== undefined}
                                // helperText={errors.extraDetails ? errors.extraDetails.message : ''}
                                label={'Add any information you may think is relevant (Optional)'}
                                fullWidth
                                {...field}
                                multiline
                                rows={4}
                                type="text"
                                variant="outlined"
                              />
                            </>}
                          />
                        </Grid>

                      </Stack>
                    </Box>


                    <Box m={3}  >
                      <Box p={2}>
                    <b>(Add Pictures: Front,Back,Inner,Side)</b>
                     </Box >
                     <Box p={2}> 
                      <ImageUpload images={images} addImages={(img) => addImages(img)} />
                      </Box>
                    </Box>
                    <Box m={3}>
                      <Divider />
                    </Box>
                    <Box m={3} container display="flex" justifyContent="center" alignItems="center" >
                      <Button type="submit" variant="contained" color="primary">
                        Submit Your Wig For Review
                      </Button>
                    </Box>

                  </Stack>
                </Box>
              </form>

            </CardContent>
          </Card>
        </Box>
      </Grid>
    </>
  );
}

export default AddWig;