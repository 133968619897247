import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../Services/firebase'
import Grid from '@mui/material/Unstable_Grid2';

function Orders() {
  const [toggleWigView, setToggleWigView] = useState(false)
  const [tableIsEmpty, setTableIsEmpty] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, 'view_orders')
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'view orders screen',
    });
  }, [])

  return (
    <>
      {/* adds affiliate link  to database */}
      <Box>
        <Card>
          
          <CardContent>

           
            <>
              <Grid mb={10} xs={12} direction={'column'} textAlign={'center'}   >

                <h1>No Orders</h1>
                <Grid display="flex" justifyContent="center" alignItems="center">
                  <Box id='stepper'> <img width={'70%'} src={"../orders.png"} /></Box>
                </Grid>
                <h2>You will receive a notification here once an order has been placed.</h2>
                
              </Grid>
            </>
          </CardContent>

        </Card>
      </Box>

    </>
  );
}

export default Orders;