import React, { useState, useEffect } from "react";
// import { TextField, Button, Box } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { Outlet,Link,useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { auth, analytics,firebase } from '../Services/firebase'
import {  logEvent ,setUserId} from "firebase/analytics";
import { signInAnonymously } from "firebase/auth";
import { useController, useForm, Controller } from "react-hook-form";
import { collection, addDoc,doc, setDoc } from "firebase/firestore";
import FirebaseLoginComponent from "./FirebaseLoginComponent";
import AddAffiliateLink from '../adminComponents/pages/Referral/AddAffiliateLink'
const useStyles = styled((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  googleButton: {
    marginTop: theme.spacing(2),
  },

}));



export default function LoginPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isUserSignIn,setIsUserSignIn] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(()=>{
    logEvent(analytics,'login')
    logEvent(analytics, 'screen_view', {
        firebase_screen: 'login', 
      });
},[])

  useEffect(() => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      setUserId(analytics,uid)
      setIsUserSignIn(true)
      navigate('/dashboard/viewWigs')
      // ...
    } else {
      setIsUserSignIn(false)
      // User is signed out
      // ...
    }
  });


  },[])

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 600,
    lineHeight: '60px',

  }));



  return (
    <>
      <Grid container display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Grid  >
          <Box m={2} >
            <Card style={{ maxWidth: "500px" }}>
              <Box m={3} display="flex" justifyContent="center" alignItems="center">
                <img width={'70%'} height={'60%'} src={"./HairAgainLogo.png"} />
              </Box>
              <CardContent >
                <FirebaseLoginComponent/>
                
              </CardContent>
            </Card>
          </Box>

        </Grid>

      </Grid>
      {/* <AddAffiliateLink/> */}

      
    </>


  );
}

