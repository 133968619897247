import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import EmailIcon from '@mui/icons-material/Email';
import Face2Icon from '@mui/icons-material/Face2';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Footer from "../../../shared/Footer";
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../Services/firebase'
function FaqPage() {
    const [toggleWigView, setToggleWigView] = useState(false)
    useEffect(() => {
        logEvent(analytics, 'faq')
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'faq',
        });
    }, [])

    return (<>

<Divider style={{padding:'30px'}} >
            <Chip color="primary" label="General" />
        </Divider>

        {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
        <Grid container xs={12} padding={3} display="flex" justifyContent="center" alignItems="center">
            <span>
            <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>How do I list my wigs for sale on your platform?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        To list your wigs for sale, simply create an account on our platform, provide all the necessary information about your wig, upload clear images, and set a reasonable price.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>What payment methods do you support?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        We currently support payment via PayPal.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>What are the requirements for the wigs I can sell on your platform?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        All wigs listed for sale on our platform must be in good condition, clean, and free from any odors. Additionally, they must be accurately described and clearly photographed.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>How do I ensure the safety and security of my wigs during shipping?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        We recommend using a reliable shipping provider and properly packaging your wigs to ensure they arrive in good condition. Additionally, providing a tracking code will help monitor the progress of your package and ensure timely delivery.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Are there any fees to list my wigs on your platform?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        No, there are no fees to list your wigs for sale on our platform. You only pay a commission fee when your wig is sold.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </span>

        </Grid>

        

        <Divider style={{padding:'30px'}} >
            <Chip color="primary" label="Selling Used Wigs" />
        </Divider>

        {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
        <Grid container xs={12} padding={3} display="flex" justifyContent="center" alignItems="center">
            <span>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Can I sell my wig used ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes you can sell your wigs that have been used, but must in good condition.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>How much can I expect from selling my wig?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            It depends on the wig condition and quality of the wig, and how much you paid for the wig. We will let you know the value of your wig with our verification methods before shipping.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>What commission fee do I pay when selling a used wig ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            For used wigs, a 20% fee is levied. In the case of Wig Makers selling their own wigs and managing shipping, the charge will be 5%.
                        </Typography>
                        <Typography> If you are selling a used wigs on our platform, a we take a commission fee of 20% . </Typography>
                        
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Do I pay for shipping?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            No! if your wig or hair piece meets our standards we will send you shipping stickers to ship to our facilities.

                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Why must the wigs be shipped to Hair Again?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            We must make sure all wigs are clean and sterilized before shipping to our clients, we also take photos product photos at our studio before listing.
                            Hair Again prides itself on quailty and we want to make sure all our clients are satisfied.

                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </span>

        </Grid>

        <Divider style={{padding:'30px'}}>
            <Chip color="primary" label="For Wig Makers" />
        </Divider>


        <Grid container xs={12} padding={3} mb={10} display="flex" justifyContent="center" alignItems="center">
            <span>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Do I have to provide a refund if a buyer is not satisfied with their purchase?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        As a wig maker, you are not required to provide a refund if a buyer is not satisfied with their purchase. However, we encourage you to work with the buyer to resolve any issues and maintain good customer relations.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>What types of wigs can I sell on your platform?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        You can sell any type of wig on our platform as long as it meets our requirements for condition, cleanliness, and accuracy in description.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>How do I handle returns?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        As a wig maker, you are not required to accept returns. However, we encourage you to work with the buyer to resolve any issues and maintain good customer relations.
                        </Typography>
                        
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Can I sell wigs that are not made by me?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        No, our platform is only for wig makers selling their own wigs. If you are interested in selling wigs that are not made by you, please contact us to discuss other options.

                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>How much commission do I pay when selling my own wigs as a wig maker?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        As a wig maker selling your own wigs and handling shipping yourself, you pay a commission fee of 5%.

                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>How do I know when my wig has been sold?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        You will receive a notification when your wig has been sold, and we will provide you with the shipping address of the buyer.

                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </span>

        </Grid>



    </>
        //  </Box>
    );
}

export default FaqPage;
