import React, { useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AffiliateLinkGenerator from "./AffiliateLinkGenerator";

function ViewReferral() {
    const wig ={
        description:  '',
        name: '',
        id: '',
        price: '',
        listingStatus: false,
        label:  '',
        
    }
    return (
      <>
        <Box>
        <Card> 
       <CardContent>
        <AffiliateLinkGenerator/>
       </CardContent>
       </Card> 
       </Box>
      </>
    );
  }
  
  export default ViewReferral;