import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './CSS/homepage.css'
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../Services/firebase'
import { Button } from "@mui/material";
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Outlet, Link, Router, useNavigate } from "react-router-dom";

function Homepage() {
    const navigate = useNavigate()
    const [toggleWigView, setToggleWigView] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    useEffect(() => {
        logEvent(analytics, 'Homepage')
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'Homepage',
        });
    }, [])

    return (<>
        {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
        {/* Desktop */}
        {!isTabletOrMobile && <Grid container xs={12} mt={'40px'} display="flex" justifyContent="center" alignItems="center" direction={'column'} >
            <Grid xs={12}>
                <h1 className="global-title">Your Wigs Have More<br /> Value Than You Think!</h1>
                <h2 className="sub-global-title"> Giving wigs a second chance at life,<br /> so someone can try the Hair Again</h2>
            </Grid>
            <Grid>
                <Button onClick={() => { navigate('/login') }} size={'large'} variant="contained" style={{ background: 'black', fontWeight: 'bolder' }} endIcon={<ArrowForwardIcon />} >Begin Selling Your Wigs Free</Button>
            </Grid>
            <Grid mt={10} display="flex" justifyContent="center" alignItems="center">
                <img width={'90%'} src={"./women.png"} />
                {/* width={'350px'} */}

            </Grid>
            {/* <h1>Listing Wigs In 3 Easy Steps!</h1> */}
            <Grid mt={10} xs={12} direction={'column'} display="flex" justifyContent="center" alignItems="center" style={{ background: 'black' }} >
                <Grid xs={6}>
                    <Box p={10} id='info' color={'white'} >
                        <h1 >Listing Wigs is Easy with Three Simple Steps!</h1>
                        {/* <p >Wigs must be listed</p> */}
                    </Box>
                </Grid>
                <Grid xs={6} p={10}>
                    <Box id='stepper'> <img width={'90%'} src={"./stepper.png"} /></Box>
                </Grid>
            </Grid>

            <Grid mt={10} xs={12} p={10} display="flex" justifyContent="center" alignItems="center"  >
                <Grid xs={6} p={10}>
                    <Box id='stepper'> <img width={'90%'} src={"./womenInYellow.png"} /></Box>
                </Grid>
                <Grid xs={6}>
                    <Box p={10} id='info'  >
                        <h1 >Sell Your Lightly Used Wigs</h1>

                        <p >Have you ever thought about how your lightly used wigs could be someone else's treasure? Many women are on the hunt for affordable wigs, and you could make some extra cash by selling your gently worn wigs instead of throwing them away. It's a win-win situation! You get to declutter your collection and make someone else feel like a million bucks without breaking the bank. So, why not spread the love and give your pre-loved wigs a new home? </p>

                    </Box>
                </Grid>

            </Grid>

            <Grid mt={10} xs={12} p={10} display="flex" justifyContent="center" alignItems="center" style={{ background: 'black' }} >

                <Grid xs={6}>
                    <Box p={10} id='info' color={'white'} >
                        <h1 >Use Your Unique Link to Refer New Sellers and Earn 20% Commission</h1>
                        <p >Use your unique link to refer new sellers to Hair Again You earn a one-time 20% commission on any new transaction that is tied to your referral link </p>

                    </Box>
                </Grid>
                <Grid xs={6} p={10}>
                    <Box id='stepper'> <img width={'90%'} src={"./earn.png"} /></Box>
                </Grid>


            </Grid>
            <Grid mt={10} xs={12} p={10} display="flex" justifyContent="center" alignItems="center"  >
                <Grid xs={6} p={10}>
                    <Box id='stepper'> <img width={'90%'} src={"./services.png"} /></Box>
                </Grid>
                <Grid xs={6}>
                    <Box p={10} id='info'  >
                        <h1 >We Spend On Advertising So You Don't Need To</h1>

                        <p > Our platform spends on advertising to help you reach a wider audience and sell more wigs. </p>

                    </Box>
                </Grid>

            </Grid>
            <Grid mt={10} xs={12} p={10} display="flex" justifyContent="center" alignItems="center" style={{ background: 'black' }} >

                <Grid xs={6}>
                    <Box p={10} id='info' color={'white'} >
                        <h1 >Your Wig-Making Business Past Instagram</h1>
                        <p >Looking to expand your wig-making business beyond your local area? Look no further! We are the largest independent wig maker marketplace, connecting wig-makers with customers from all over the world. Say goodbye to the limitations of Instagram and hello to a wider audience and increased sales! </p>
                        <p>Whether you're based in Canada or the USA, our platform makes it easy for you to showcase your skills and sell your unique creations to a global customer base. And hey, who knows? You might just become the next big thing in the world of wigs! </p>
                        <p > So, why limit yourself to your immediate surroundings when you can take your business to the next level with our platform? Join us today and watch your business grow beyond your wildest dreams! </p>

                    </Box>
                </Grid>
                <Grid xs={6} p={10}>
                    <Box id='stepper'> <img width={'70%'} src={"./Global.png"} /></Box>
                </Grid>


            </Grid>
            <Grid mt={10} xs={12} p={10}    >
                <Grid >
                    <h1>Begin Selling Your Wigs Today </h1>
                </Grid>

                <Grid>
                    <Button onClick={() => { navigate('/login') }} variant="contained" size="large" style={{ background: 'black', fontWeight: 'bolder' }} endIcon={<ArrowForwardIcon />} >Join Us</Button>
                </Grid>



                {/* <Grid xs={6}>
                    <Box p={10} id='info'  >
                        <h1 >We Spend On Advertising So You Don't Need To</h1>
                        <p > Reach a wider audenice and make more for wigs </p>

                    </Box>
                </Grid>
                <Grid xs={6} p={10}>
                    <Box id='stepper'> <img width={'60%'} src={"./earn.png"} /></Box>
                </Grid> */}


            </Grid>





        </Grid>}

        {/* Mobile */}
        {isTabletOrMobile && <Grid container xs={12} mt={'40px'} display="flex" justifyContent="center" alignItems="center" direction={'column'} >
            <Grid xs={12}>
                <h1 className="global-title-mobile">Your Wigs Have More<br /> Value Than You Think!</h1>
                <h2 className="sub-global-title-mobile"> Giving wigs a second chance at life,<br /> so someone can try the Hair Again</h2>
            </Grid>
            <Grid>
                <Button onClick={() => { 
                    navigate('/login') 
                    logEvent(analytics, 'User Clicked Top Join Us On Homepage')
                    }} variant="contained" style={{ background: 'black', fontWeight: 'bolder' }} endIcon={<ArrowForwardIcon />} >Begin Selling Your Wigs Free</Button>
            </Grid>
            <Grid mt={10} display="flex" justifyContent="center" alignItems="center">
                <img width={'360px'} src={"./women.png"} />
                {/* width={'350px'} */}

            </Grid>
            {/* <h1>Listing Wigs In 3 Easy Steps!</h1> */}
            <Grid mt={10} xs={12} direction={'column'} style={{ background: 'black' }} >
                <Box>
                    <Grid >
                        <Box p={4} id='info' color={'white'} >
                            <h1 className="header-info" >Listing Wigs is Easy with Three Simple Steps!</h1>
                            {/* <p >Wigs must be listed</p> */}
                        </Box>
                    </Grid>
                    <Grid p={1} display="flex" justifyContent="center" alignItems="center" >
                        <Box id='stepper'> <img width={'350px'} src={"./stepper.png"} /></Box>
                    </Grid>
                </Box>
            </Grid>

            <Grid mt={4} xs={12} direction={'column'}  >
                <Grid >
                    <Box p={4} id='info'  >
                        <h1 className="header-info"  >Sell Your Lightly Used Wigs And Make Money</h1>

                        <p >Have you ever thought about how your lightly used wigs could be someone else's treasure? Many women are on the hunt for affordable wigs, and you could make some extra cash by selling your gently worn wigs instead of throwing them away. It's a win-win situation! You get to declutter your collection and make someone else feel like a million bucks without breaking the bank. So, why not spread the love and give your pre-loved wigs a new home? </p>

                    </Box>
                </Grid>

                <Grid p={4} display="flex" justifyContent="center" alignItems="center">
                    <Box id='stepper'> <img width={'350px'} src={"./womenInYellow.png"} /></Box>
                </Grid>


            </Grid>

            <Grid mt={10} xs={12} direction={'column'} style={{ background: 'black' }} >

                <Grid >
                    <Box p={4} id='info' color={'white'} >
                        <h1 style={{ textAlign: 'center' }} className="header-info">Use Your Unique Link to Refer New Sellers and Earn 20% Commission</h1>
                        <p >Use your unique link to refer new sellers to Hair Again You earn a one-time 20% commission on any new transaction that is tied to your referral link </p>


                    </Box>
                </Grid>
                <Grid mb={10} display="flex" justifyContent="center" alignItems="center">
                    <Box p={4} id='stepper'> <img width={'350px'} src={"./earn.png"} /></Box>
                </Grid>


            </Grid>
            <Grid mt={6} xs={12} direction={'column'}  >

                <Grid >
                    <Box p={4} id='info' textAlign={'center'}  >
                        <h1 className="header-info">We Spend On Advertising So You Don't Need To</h1>

                        <p > Our platform spends on advertising to help you reach a wider audience and sell more wigs. </p>

                    </Box>
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center">
                    <Box p={4}> <img width={'350px'} src={"./services.png"} /></Box>
                </Grid>

            </Grid>
            <Grid mt={10} xs={12} direction={'column'} style={{ background: 'black' }} >

                <Grid >
                    <Box p={4} id='info' color={'white'} >
                        <h1 style={{ textAlign: 'center' }} className="header-info">Your Wig-Making Business Past Instagram</h1>

                        <p >Looking to expand your wig-making business beyond your local area? Look no further! We are the largest independent wig maker marketplace, connecting wig-makers with customers from all over the world. Say goodbye to the limitations of Instagram and hello to a wider audience and increased sales! </p>
                        <p>Whether you're based in Canada or the USA, our platform makes it easy for you to showcase your skills and sell your unique creations to a global customer base. And hey, who knows? You might just become the next big thing in the world of wigs! </p>
                        <p > So, why limit yourself to your immediate surroundings when you can take your business to the next level with our platform? Join us today and watch your business grow beyond your wildest dreams! </p>

                    </Box>
                </Grid>
                <Grid display="flex" justifyContent="center" alignItems="center" style={{ background: 'black' }}>
                    <Box id='stepper'> <img width={'350px'} src={"./Global.png"} /></Box>
                </Grid>


            </Grid>
            <Grid mt={10} xs={12} p={5}   >
                <Grid m={3} textAlign={'center'} >
                    <h1 className="header-info">Begin Selling Your Wigs Today </h1>
                </Grid>

                <Grid display="flex" justifyContent="center" alignItems="center">
                    <Button onClick={() => {
                        navigate('/login')
                        logEvent(analytics, 'User Clicked Bottom Join Us On Homepage')
                    }} variant="contained" size="large" style={{ background: 'black', fontWeight: 'bolder' }} endIcon={<ArrowForwardIcon />} >Join Us</Button>
                </Grid>



                {/* <Grid xs={6}>
                    <Box p={10} id='info'  >
                        <h1 >We Spend On Advertising So You Don't Need To</h1>
                        <p > Reach a wider audenice and make more for wigs </p>

                    </Box>
                </Grid>
                <Grid xs={6} p={10}>
                    <Box id='stepper'> <img width={'60%'} src={"./earn.png"} /></Box>
                </Grid> */}


            </Grid>





        </Grid>}

    </>
        //  </Box>
    );
}

export default Homepage;
