import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AddWig from './AddWig';
import WigTable from './WigTable'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import AddAffiliateLink from "../Referral/AddAffiliateLink"
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../Services/firebase'
import Grid from '@mui/material/Unstable_Grid2';

function ViewWigs() {
  const [toggleWigView, setToggleWigView] = useState(false)
  const [tableIsEmpty, setTableIsEmpty] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, 'view_wigs')
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'view wig screen',
    });
  }, [])

  return (
    <>
      {/* adds affiliate link  to database */}
      <AddAffiliateLink />
      <Box>
        <Card>
          <Box m={3} display="flex" justifyContent="center" alignItems="center" >
            {tableIsEmpty &&<Button variant="contained" onClick={() => {
              navigate('/dashboard/addWig')
              logEvent(analytics, 'user clicked add wig')
            }} endIcon={<AddIcon />}>
              Add Hair Piece
            </Button>}
          </Box>
          <CardContent>

            {tableIsEmpty && <WigTable setTableIsEmpty={() => { setTableIsEmpty(false) }} />}
            {!tableIsEmpty && <>
              <Grid mb={10} xs={12} direction={'column'} textAlign={'center'}   >

                <h1>Welcome To Hair Again!</h1>
                <Grid display="flex" justifyContent="center" alignItems="center">
                  <Box id='stepper'> <img width={'70%'} src={"../welcome.png"} /></Box>
                </Grid>
                <h2>Begin By Adding The Wig/Hair You Want Listed</h2>
                <Button variant="contained" onClick={() => {
                  navigate('/dashboard/addWig')
                  logEvent(analytics, 'user clicked add wig')
                }} endIcon={<AddIcon />}>
                  Add Hair Piece
                </Button>
              </Grid>
            </>}
          </CardContent>

        </Card>
      </Box>

    </>
  );
}

export default ViewWigs;