import React, { useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { InputAdornment, Typography } from "@mui/material";
import { useController, useForm, Controller } from "react-hook-form";



function AddReferral() {
    const wig ={
        description:  '',
        name: '',
        id: '',
        price: '',
        listingStatus: false,
        label:  '',
        
    }

    const { control, handleSubmit } = useForm({
        defaultValues: {
          email: '',
          password: ''
        }
      });

      const onSubmit = data => {
        console.log(data, 'daft')
      };

    return (
      <>
      <Grid> 
        <Box>
        <Card >
        <Typography variant="h5">Seller Login</Typography>
        <CardContent >
        <form onSubmit={handleSubmit(onSubmit)}>
                  <Box >
                    <Stack  >
                    
                    
                    <Box m={3} >
                      <Controller
                      
                        name="email"
                        control={control}
                        render={({ field }) => <TextField
                        fullWidth={true}
                          {...field}
                          label="Email"
                          type="email"
                          variant="outlined"
                        />}
                      />

                    </Box>
                    <Divider>
                        <Chip label="OR" />
                    </Divider>
                    <Box m={3}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => <TextField
                        fullWidth
                          {...field}
                          type="password"
                          label="ID"
                          variant="outlined"
                        />}
                      />

                    </Box>
                    <Box m={1} container display="flex" justifyContent="center" alignItems="center" >
                      <Button type="submit" variant="contained" color="primary">
                        Add
                      </Button>
                    </Box>
                    
                    </Stack>
                  </Box>
                </form>
        </CardContent>
       </Card> 
       </Box>
       </Grid>
      </>
    );
  }
  
  export default AddReferral;