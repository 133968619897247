import React, { useEffect, useState, useContext } from "react";
import { Outlet, Link, useNavigate, useMatch, useLocation } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import { auth, firebase,analytics } from '../Services/firebase'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from 'react-burger-menu/lib/menus/slide'
import { Stack } from "@mui/material";
import { logEvent } from "firebase/analytics";
var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: 'white',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)

  return (
    <MyContext.Provider value={{
      isMenuOpen: menuOpenState,
      toggleMenu: () => setMenuOpenState(!menuOpenState),
      stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
    }}>
      {props.children}
    </MyContext.Provider>
  )
}



// create a navigation component that wraps the burger menu
const Navigation = (props) => {
  const ctx = useContext(MyContext)
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  
  const closeMenu = () => {
    console.log('its all working')
    ctx.toggleMenu()
    logEvent(analytics, 'user closed Menu Bar')
  }
  return (
    <Menu
      styles={styles}
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
    >
      <Box m={3} display="flex" justifyContent="center" alignItems="center"  >
        <img width={'90%'} height={'90%'} src={"../HairAgainLogo.png"} />
      </Box>
      {props.isUserSignedIn&&<Stack direction={'column'}>
        <Box >
          <a onClick={() => {
            closeMenu()
            navigate("/dashboard/viewWigs")
            logEvent(analytics, 'user clicked in menu My Wigs')

          }}>Wigs</a>
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            navigate("/dashboard/referrals")
            logEvent(analytics, 'user clicked in menu My Referrals')


          }}>Referrals</a>
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            navigate("/dashboard/orders")
            logEvent(analytics, 'user clicked in menu My Orders')


          }}>Orders</a>
        </Box>
        {/* <Box>
          <a onClick={() => {
            closeMenu()
            logEvent(analytics, 'user clicked in menu Hair Again Store')
            window.open('https://hairagainshop.com/',"_blank")


          }}>Hair Again Store</a>
        </Box> */}
        <Box p={3}> 
        <Divider />
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            logEvent(analytics, 'user clicked in menu Hair Again Store')
            window.open('https://hairagainshop.com/',"_blank")


          }}>Hair Again Store</a>
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            navigate("/faq")
            logEvent(analytics, 'user clicked in menu FAQ')

          }}>FAQ</a>
        </Box>
        
        <Box p={3}> 
        <Divider />
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            logEvent(analytics, 'user clicked in menu Logout')
            signOut(auth).then(() => {
              navigate("/login")
            })

          }}>Logout</a>
        </Box>



      </Stack>}

      {!props.isUserSignedIn&&<Stack direction={'column'}>
        <Box >
          <a onClick={() => {
            closeMenu()
            navigate("/homepage")

          }}>Home</a>
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            navigate("/login")

          }}>Sign-Up</a>
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            navigate("/faq")


          }}>FAQ</a>
        </Box>
        <Box>
          <a onClick={() => {
            closeMenu()
            navigate("/about-us")


          }}>About</a>
        </Box>
        


      </Stack>}

    </Menu>
  )
}

// create a button that calls a context function to set a new open state when clicked
const MenuButton = () => {
  const ctx = useContext(MyContext)
  logEvent(analytics, 'user opened Menu Bar')
  return (
    <IconButton onClick={ctx.toggleMenu} aria-label="menu" >
      <MenuIcon fontSize="large" />
    </IconButton>
  )
}
export default function Layout() {

  const [isUserSignedIn, setIsUserSignedIn] = useState(false)
  const [path, setPath] = useState(false);
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  // const { pathname } = useLocation();
  // make a new context
  useEffect(() => {
    const currentPath = window.location.href;
    const endPath = currentPath.slice(currentPath.lastIndexOf("/"), currentPath.length)
    const isAffiliatePage = (pathname.split('/')[1] === 'affiliate'  ? true:false)

    console.log('holy sword', pathname)
  

    setPath(endPath)

    console.log('spirit poing', endPath)
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('checking users',user)
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setIsUserSignedIn(true)
        if (pathname !== "/how-it-works" && pathname !== "/faq" && pathname !== 'about-us') {
          navigate("/dashboard/viewWigs")
        }
        // ...
      } else {
        setIsUserSignedIn(false)
        // User is signed out
        // ...
      
        if(isAffiliatePage){
          navigate("/homepage")
        }else if ( pathname !== "/terms-of-service" &&pathname !== "/privacy-policy" && pathname !== "/wigmakers" && pathname !== "/sellers" && pathname !== "/homepage" && pathname !== "/how-it-works" && pathname !== "/faq" && pathname !== 'about-us' && pathname != '/how-it-works-wig-maker') {

          navigate("/login")
        }

      }
    });
  }, [isUserSignedIn])




  return (
    <>
      <MyProvider>
        <Navigation isUserSignedIn={isUserSignedIn} />
        <div id="outer-container">
          {

            <AppBar elevation={0} position="static" color="transparent">
              <Toolbar  >

                {/* <Box display="flex" justifyContent="start" alignItems="start" sx={{ flexGrow: .5 }}>
                  {isUserSignedIn && <MenuButton />}
                </Box> */}





                {/* <Box m={3} display="flex" justifyContent="center" alignItems="center" >
                  {pathname == '/dashboard/addWig' && <h2 >Add Wig</h2>}
                  {pathname == '/dashboard/viewWigs' && <h2 >My Wigs</h2>}
                  {pathname == '/dashboard/referrals' && <h2 >My Referrals</h2>}
                </Box> */}
                {pathname != '/login' && <Box p={3} sx={{ flexGrow: 1 }} >
                  <img width={'250px'} src={"../HairAgainLogo.png"} onClick={() => { 
                    if(isUserSignedIn){
                    navigate('/login') 
                  }else{
                    navigate('/homepage') 
                  }
                    
                    }} />
                </Box>}

                {/* <Box display="flex" justifyContent="start" alignItems="start" sx={{ flexGrow: .5 }}>
                  {isUserSignedIn && <MenuButton />}
                </Box> */}
                {pathname != '/login' && <Box display="flex" justifyContent="start" alignItems="start" >
                  {<MenuButton />}
                </Box>}
              </Toolbar>
              {/* <Box m={3} display="flex" justifyContent="start" alignItems="start">
                <MenuButton />
                <img width={'20%'} height={'60%'} src={"../HairAgainLogo.png"} onClick={() => { navigate('/login') }} />
              </Box> */}
              {/* <Grid xs={12} m={3} >
                <Grid xs={1} display="flex" justifyContent="start" alignItems="start">
                <MenuButton /> 
                </Grid>
                <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
                <img width={'20%'} height={'60%'} src={"../HairAgainLogo.png"} onClick={() => { navigate('/login') }} />
                </Grid>
              </Grid> */}

            </AppBar>}

          <div id="page-wrap" >
            <Box ml={3} display="flex" justifyContent="start" alignItems="start" >
              {pathname == '/dashboard/addWig' && <h2 >Add Your Wig</h2>}
              {pathname == '/dashboard/viewWigs' && <h2 >My Wigs</h2>}
              {pathname == '/dashboard/referrals' && <h2 >My Referrals</h2>}
              {pathname == '/how-it-works' && <h2 >Process For Selling Your Used Wig</h2>}
              {pathname == '/dashboard/faq' && <h2 >FAQ</h2>}
              {pathname == '/about-us' && <h2 >About Us</h2>}
              {pathname == '/how-it-works-wig-maker' && <h2 >Process For Wig Makers Selling On Our Marketplace</h2>}
              {pathname == '/faq' && <h2 >FAQ</h2>}
            </Box>
            <Outlet />
          </div>
        </div>
      </MyProvider>
      {/* <hr/>
       <p>footer</p> */}
    </>
  );
}
