import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";



// Firebase Config
const firebaseConfig = {

    apiKey: "AIzaSyAQPzTdrJ97FhcCyF2Upa0LHbgBVR9oKrw",

    authDomain: "seller.hairagainshop.com",

    projectId: "hair-again-3cae9",

    storageBucket: "hair-again-3cae9.appspot.com",

    messagingSenderId: "499384402476",

    appId: "1:499384402476:web:da54f76b42630ad4200789",

    measurementId: "G-B3LLPD5GJ9"
    

};


// export default { firebaseConfig }
const firebase = initializeApp(firebaseConfig);
// export default firebase;

const analytics = getAnalytics(firebase);
const firestore = getFirestore(firebase);
const auth = getAuth(firebase);
const storage = getStorage(firebase);

export {
    firestore,
    auth,
    storage,
    firebase,
    firebaseConfig,
    analytics
}
