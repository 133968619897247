import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
function AdminComponent() {
    return (
      <>
      
        <Grid container spacing={2}>

       {/* <Grid xs={2}>
        <p>Admin Dash</p>
        <nav>
        <Link to="referrals">Referrals </Link>/
        <Link to="addWig">Add Wig </Link>/
        <Link to="viewWigs">View Wig </Link>
      </nav>
         </Grid> */}
          
       <Grid xs={12}> <Outlet /></Grid>
         </Grid>
       
      </>
    );
  }
  
  export default AdminComponent;