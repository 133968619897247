import React, { useState,useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import EmailIcon from '@mui/icons-material/Email';
import Face2Icon from '@mui/icons-material/Face2';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Footer from "../../../shared/Footer";
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {  logEvent } from "firebase/analytics";
import {analytics } from '../../../Services/firebase'
function AboutUs() {
    const [toggleWigView, setToggleWigView] = useState(false)
    useEffect(()=>{
        logEvent(analytics,'aboutUs')
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'aboutUs', 
          });
    },[])

    return (<>
        {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
        <Grid container xs={12} padding={3}  display="flex" justifyContent="center" alignItems="center">
            <span>
                <p>Hair Again is more than just a marketplace for used wigs and hair pieces - it's a community for women to find their perfect look at a fraction of the cost. Our platform was founded in 2020, with a mission to shake up the affordability of beauty and empower women through the beauty of hair.</p>
                <p>We understand that finding the perfect hair can be a daunting task, especially when it comes to the cost. That's why we're here to make it easier for you. Our platform allows you to browse and purchase pre-loved hair at a fraction of the cost, while also giving hair a second chance at a new life.</p>
                <p>We believe that every woman deserves to feel beautiful, and that's why we're dedicated to providing a variety of options for all hair types and styles. From long, luscious locks to short and sassy cuts, we've got you covered. Our sellers list their gently-used wigs and hair pieces, making it easy for you to find the perfect match for you.</p>
                <p>And for those looking to sell their pre-loved hair, our platform makes it easy for you to list and sell your hair pieces. This is a great way to make some extra cash and also give your hair a new home. Our community of buyers are always looking for the perfect hair to add to their collection.</p>
                <p>At Hair Again, we believe in the value of sustainability and affordability. We're dedicated to providing a platform that is easy to use and accessible to all. By joining our community, you'll not only be able to find the perfect hair for you, but you'll also be making a conscious effort to be more sustainable.</p>
                <p>So, whether you're in the market for a new wig or hair piece, or looking to sell your pre-loved hair, Hair Again is the perfect platform for you. Join us today and discover the beauty of Hair Again!</p>
            </span>
        </Grid>

    </>
        //  </Box>
    );
}

export default AboutUs;
